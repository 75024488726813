import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class RegionsDataService extends APIBaseFunctions {
  private cachedRegionsDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = RegionsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedRegionsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/regions?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/regions?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/regions/${id}`)
  }

  getCount () {
    return http.get('/regions/count')
  }

  create (data: any) {
    return http.post('/regions', data)
  }

  update (id: string, data: any) {
    return http.put(`/regions/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/regions/${id}`)
  }

  deleteAll () {
    return http.delete('/regions')
  }
}

export default new RegionsDataService()

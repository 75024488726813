import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import BaseFunctions from '@/components/Utility/Base'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub, { WheelchairFriendlyType } from '@/types/SysClub'
import GoogleMaps from '../GoogleMaps/index.vue'

type dataReturnType = { klubbers: any; error: any; totalPages: number; }
@Options({
  components: {
    GoogleMaps
  }
})
export default class theClubList extends Vue {
  private klubbers: SysClub[] = []
  error: any = null
  clubSearchInputValue = ''
  clubSearchTerm = ''
  private oldSort = ''
  private currentListSortingOrder = 'klubber_klubnavn:asc'
  regionOptions: string[] = ['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5']
  oldSearchInputValue = ''
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  allClubsData = []
  isResetSearch = false

  readonly name : string = 'theClubList'
  data (): dataReturnType {
    return {
      klubbers: this.klubbers,
      error: this.error,
      totalPages: this.totalPages
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveClubs()
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'klubber_klubnavn:asc'
      this.oldSort = ''
      this.clubSearchInputValue = ''
      this.clubSearchTerm = ''
      sessionStorage.clear()
      this.isResetSearch = true
      this.currentPage = 1
    }
    if (sortBy === 'klubnavn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_klubnavn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_klubnavn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'klubby') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'bypost_id.bypost_by:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'bypost_id.bypost_by:desc'
        this.oldSort = ''
      }
    }

    this.retrieveClubs()
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveClubs()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveClubs()
  }

  public resetSearch () : void {
    this.clubSearchTerm = ''
    this.clubSearchInputValue = ''
    this.retrieveClubs()
  }

  public findClub () : void {
    console.log('Search club.')
    this.clubSearchTerm = this.clubSearchInputValue
    sessionStorage.setItem('clubSearchTerm', this.clubSearchTerm)
    this.isResetSearch = false
    this.retrieveClubs()
  }

  public async retrieveClubs () : Promise<void> {
    try {
      this.totalPages = Number((await ClubsDataService.getCount('klubber_status=true')).data)
      this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
    } catch (err) {
      console.log(err)
      this.totalPages = 0
    }

    if (this.clubSearchTerm.length > 0) {
      ClubsDataService.findBySearchTerm(true, true, this.clubSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, 'klubber_status=true')
        .then((response) => {
          this.klubbers = response.data
          this.forceRerenderClubsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      ClubsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, 'klubber_status=true')
        .then((response) => {
          this.klubbers = response.data
          this.forceRerenderClubsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public forceRerenderClubsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public toClubInfo (klubSlug : string) : void {
    this.$router.push({ name: 'theClub', params: { slug: klubSlug } })
  }

  public async retrieveAllClubs () : Promise<void> {
    await ClubsDataService.getAll('', null, 'klubber_status=true')
      .then((response) => {
        this.allClubsData = response.data
      })
      .catch((err) => {
        this.error = err
      })
  }

  get clubLocationInfo () : any {
    if (this.allClubsData.length > 0) {
      const locationInfo = this.allClubsData.map((club: any) => {
        return {
          position: { lat: club.klubber_latitude, lng: club.klubber_longitude },
          title: club.klubber_klubnavn,
          klubber_slug: club.klubber_slug
        }
      })
      return locationInfo
    } else {
      return []
    }
  }

  public checkIsPreviousSearched (): void {
    const searchedStorageStr = sessionStorage.getItem('clubSearchTerm')
    if (searchedStorageStr) {
      this.clubSearchTerm = searchedStorageStr
      this.clubSearchInputValue = this.clubSearchTerm
    }
  }

  async mounted () : Promise<void> {
    this.retrieveClubs()
    this.retrieveAllClubs()
    this.checkIsPreviousSearched()
  }
}

import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions from '@/components/Utility/Base'
import ExternalServicesDataService from '@/services/ExternalServicesDataService'
import SysExternalServices from '@/types/SysExternalServices'

type ClubLocation = {
  position: {
    lat: number;
    lng: number;
  };
  title: string;
  klubber_slug: string;
}
type ClubLocationArray = ClubLocation[]
@Options({
  props: {
    locationInfo: Array,
    locationSearchQuery: String,
    isMapReset: Boolean
  }
})
export default class googleMaps extends Vue {
  locationInfo!: ClubLocationArray
  locationSearchQuery!: string
  isMapReset!: boolean
  center = { lat: 55.39594, lng: 10.38831 }
  zoom = 8
  isMapScriptLoaded = false
  searchQuery = ''
  markers: any = []
  externalservices = {} as SysExternalServices
  scriptElement: any = null

  public loadGoogleMaps () : void {
    const apiKey = BaseFunctions.defDercpyt(this.externalservices.gms_key)
    if (!document.querySelector(`script[src*='maps.googleapis.com/maps/api/js?key=${apiKey}']`)) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=maps,marker,places&v=beta`
      script.defer = true
      script.async = true
      script.onload = this.initMap
      this.scriptElement = script
      document.head.appendChild(this.scriptElement)
    } else {
      this.initMap()
    }
  }

  public async initMap () {
    this.isMapScriptLoaded = true
    this.checkIsPreviousSearched()
  }

  @Watch('locationSearchQuery')
  onLocationSearchQueryChange () : void {
    if (this.locationSearchQuery) {
      this.searchQuery = this.locationSearchQuery
      this.searchLocation()
    }
  }

  @Watch('isMapReset')
  onMapReset () : void {
    if (this.isMapReset) {
      this.resetMap()
    }
  }

  public searchLocation () : void {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ address: this.searchQuery }, (results: any, status: any) => {
      if (status === 'OK' && results[0]) {
        this.center = results[0].geometry.location
        this.zoom = 12
      } else {
        alert('Geocode does not find the location:' + status)
      }
    })
  }

  public resetMap () : void{
    this.center = { lat: 55.39594, lng: 10.38831 }
    this.zoom = 8
    this.searchQuery = ''
  }

  public selectLocation (location: any) : void {
    this.$router.push({ name: 'theClub', params: { slug: location.klubber_slug } })
  }

  public async retrieveExternalServices () : Promise<void> {
    await ExternalServicesDataService.get('1')
      .then((response) => {
        this.externalservices = response.data
        if (this.externalservices.gms_key) {
          this.loadGoogleMaps()
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public checkIsPreviousSearched (): void {
    const searchedStorageStr = sessionStorage.getItem('clubSearchTerm')
    if (searchedStorageStr) {
      this.searchQuery = searchedStorageStr
      this.searchLocation()
    }
  }

  async mounted () : Promise<void> {
    this.retrieveExternalServices()
  }

  onUnmounted () : void {
    if (this.scriptElement?.parentNode) {
      this.scriptElement.parentNode.removeChild(this.scriptElement)
    }
  }
}

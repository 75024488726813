
import { Options, Vue } from 'vue-class-component'
import AdminClubs from '@/components/Clubs/AdminClubs/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminClubs
  }
})
export default class ClubsView extends Vue {}
